import Calc from "./Calc";

export default function Home() {
    return (
        <>
            <div>
            <br/>
            <br/>
            <br/>
            <div className='mainHolder'>
                <div className='eyeCatch'> Credit Newbie is the first step of your journey to financial literacy.</div>
                <br/>
                <br/>
                <br/>
                <div className='meatParagraph'>
            <br/>
            </div>
            </div>
            <br/>
            <br/>
        </div>
        </>

    );
}