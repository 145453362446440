
import CreditCardWidgets from '../Components/CreditCardWidgets';
export default function CardsDefaultAll() {
    return (
        <>
           <div className="">
                <CreditCardWidgets />
           </div>
        </>

    );
}