import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Nav, NavbarBrand, Form, Row, Col, Button } from 'react-bootstrap';
import CardBlock from './CardBlock';
import { useEffect, useState } from 'react';
import { supabase } from '../client';

export default function CreditCardWidgets () {

    const [cardsPull, setCardsPull] = useState([]);

    useEffect(() => {
        getCards();
    }, [])

    async function getCards(){
        try {
            const { data, error} = await supabase
            .from('card')
            .select('*')
            if (error) throw (error);
            if (data != null) {
                setCardsPull(data);
            }
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <>
            <Navbar>
                <Container>
                    <Navbar.Brand></Navbar.Brand>
                    <Nav>
                        <Nav.Item></Nav.Item>
                    </Nav>
                    <Nav>

                    </Nav>
                </Container>
            </Navbar>
            <br/>
            <Container>
                <Row>
                    <Col>
                        <h2>The Credit Cards that go into our calculations can be seen below:</h2>
                        {/* <Form.Label>Select issuer</Form.Label>
                        <Form.Control
                            type='text'
                            id='cardIssuer'
                        /> */}

                        <br />
                    </Col>
                </Row>
                <br/>
                <hr></hr>
                <h2></h2>
                <Row >
                    {cardsPull.map((cardsPull) => (
                        <Col>
                        <CardBlock cardsPull={cardsPull}/></Col>
                        
                        
                    ))}
                </Row>
            </Container>

        </>
    );
}













// import { useState , useEffect } from 'react';
// import CSP from './Assets/sapphire_preferred_card.png';

// const csp = {
//     "cardName" : "Chase Sapphire Preferred",
//     "cardIssuer" : "Chase",
//     "pointsOrCash" : "Points",
//     "annualFee" : "$95",
//     "recMinScore" : "670"
// }



// const CreditCardWidgets = () => {
//     return (
//         <div className='cardBlock'>
//             <div className='cardPicture'>
//                 <img src={CSP} alt='Chase Sapphire Preferred' height='auto' id='cardImg'/>
//             </div>
//             <div>
//             <div className='cardInfo'>
//                 <strong>
//                 Card Name: {csp.cardName} &nbsp;
//                 <br />
//                 Card Issuer: {csp.cardIssuer} &nbsp;
//                 <br />
//                 Rewards Type: {csp.pointsOrCash} &nbsp;
//                 <br />
//                 Annual Fee: {csp.annualFee} &nbsp;
//                 <br />
//                 Reccommended Credit Score: {csp.recMinScore}+ &nbsp;
//                 <br />
//                 </strong>
//             </div>
//             </div>
//         </div>
        
//     );
// }

// export default CreditCardWidgets;

{/* <img 
                src={CSP}
                alt='Chase Sapphire Preferred'
            /> */}

//<img src={className.PictureTitle !== 'api call'(in the example 'N/A') ? csp.CardPicture : 'https://via.placeholder.com/250'}