import React from 'react';
import '../App.css';
import { useState , useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button, Form, CardImg, CImage, CardGroup, CardText, Img, Container, Row, Col} from 'react-bootstrap';
import CALCSOURCE from '../CalcSource.json';

export default function Calc() {

    const [ creditscoreinput, setCreditscoreinput] = useState('');        //credit score int

    const [ travelinquiryinput, setTravelinquiryinput] = useState('false');  //bool to decide if the travel inputs show

    const [annualportalflighttotalinput, setAnnualportalflighttotalinput] = useState('');   //main travel inputs
    const [annualflighttotalinput, setAnnualflighttotalinput] = useState('');
    const [ annualportalHotelTotalinput, setAnnualportalHotelTotalinput] = useState('');
    const [ annualHotelTotalinput, setAnnualHotelTotalinput] = useState('');

    const [ annualGroceryTotalinput, setAnnualGroceryTotalinput] = useState(''); //the standard life inputs
    const [ annualOtherTravelTotalinput, setAnnualOtherTravelTotalinput] = useState('');
    const [ annualDiningTotalinput, setAnnualDiningTotalinput] = useState('');
    const [ annualDrugstoreTotalinput, setAnnualDrugstoreTotalinput] = useState('');
    const [ othertotalspendinput, setOthertotalspendinput] = useState('');

    const [array1, setArray1] = useState([1,1,1,1,1,1,1,1,1,1]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setArray1([
            annualportalflighttotalinput,
            annualflighttotalinput,
            annualportalHotelTotalinput,
            annualHotelTotalinput,
            annualGroceryTotalinput,
            annualOtherTravelTotalinput,
            annualDiningTotalinput,
            annualDrugstoreTotalinput,
            othertotalspendinput,
            creditscoreinput
            
        ])
      };
      console.log(travelinquiryinput)
    return (
        
        
        <Container className=''>
            <div className=''>
            <div>
            </div>
            <br />
            <br />
            <br />
            <div className='LoginHeader'>
            <h3>On this page, you can input spend category values to calculate an annual return on spend in the form of different redemption methods</h3>

            </div>
            <br />
            <br />

                <form  className=''>
                    <label>Please input your Credit Score</label><br />
                    <input
                    value={creditscoreinput}
                    type="number"
                    required
                    onChange={(e)=>setCreditscoreinput(e.target.value)}
                    />
                    <br />
                    <label>Do you travel?</label><br />
                    <select
                    value={travelinquiryinput}
                    onChange={(e)=>setTravelinquiryinput(e.target.value)}
                    >
                        <option value= {true} className={travelinquiryinput==='true'?'submitGrey' : 'submit'} onClick={()=>{setTravelinquiryinput('true')}}>Yes</option>
                        <option value={false} className={travelinquiryinput==='true'?'submitGrey' : 'submit'} onClick={()=>{setTravelinquiryinput('false')}}>No</option>
                    </select>
                    <br />
                    {travelinquiryinput === "true" ?
                    <div>
                        <label>Annual flight expenditures through a card issuer's travel portal:</label><br />
                        <input
                        value={annualportalflighttotalinput}
                        type="number"
                        required
                        onChange={(e)=>setAnnualportalflighttotalinput(e.target.value)}
                        />
                        <br />
                        <label>Annual flight expenditures through other means:</label><br />
                        <input
                        value={annualflighttotalinput}
                        type="number"
                        required
                        onChange={(e)=>setAnnualflighttotalinput(e.target.value)}
                        />
                        <br />
                        <label>Annual hotel expenditures through a card issuer's travel portal:</label><br />
                        <input
                        value={annualportalHotelTotalinput}
                        type="number"
                        required
                        onChange={(e)=>setAnnualportalHotelTotalinput(e.target.value)}
                        />
                        <br />
                        <label>Annual hotel expenditures through other means:</label><br />
                        <input
                        value={annualHotelTotalinput}
                        type="number"
                        required
                        onChange={(e)=>setAnnualHotelTotalinput(e.target.value)}
                        />
                    </div>
                    :
                    <></>
                    }
                    <br />
                    <label>Annual spend on grocery store purchases:</label><br />
                    <input
                    value={annualGroceryTotalinput}
                    type="number"
                    required
                    onChange={(e)=>setAnnualGroceryTotalinput(e.target.value)}
                    />
                    <br />
                    <label>Some cards have travel multipliers for trasit or rideshare, estimate annual total expenditures here:</label><br />
                    <input
                    value={annualOtherTravelTotalinput}
                    type="number"
                    required
                    onChange={(e)=>setAnnualOtherTravelTotalinput(e.target.value)}
                    />
                    <br />
                    <label>Enter annual spend on dining or ordering out:</label><br />
                    <input
                    value={annualDiningTotalinput}
                    type="number"
                    required
                    onChange={(e)=>setAnnualDiningTotalinput(e.target.value)}
                    />
                    <br />
                    <label>Enter annual spend at drugstores:</label><br />
                    <input
                    value={annualDrugstoreTotalinput}
                    type="number"
                    required
                    onChange={(e)=>setAnnualDrugstoreTotalinput(e.target.value)}
                    />
                    <br />
                    <label>Any annual expenditures that don't fit into the previous categories can be input here:</label><br />
                    <input
                    value={othertotalspendinput}
                    type="number"
                    required
                    onChange={(e)=>setOthertotalspendinput(e.target.value)}
                    />
                    <br />
                    <br/>
                    
                    <button className='' onClick={handleSubmit}>Enter</button>
                    
                    <div>
            
                    </div>
                </form>
                <br/>
                <br/>
                <div>
                <hr></hr>
                <h3></h3>
                <Row className=''>
                        {CALCSOURCE.map((calcDetail, index) => {
                            
                            return <Card style={{width: "23rem"}} className='cardBlock'>
                                    <CardImg src={calcDetail.cardim} classname='cardPicture'/>
                                    <Card.Text>Possible returns in the form of a statement credit:</Card.Text>
                                    <Card.Body key={index}>
                                   $ {
                                    (Math.round (((
                                            (array1[0] * calcDetail.portalflights)+
                                            (array1[1] * calcDetail.nonportalflights)+
                                            (array1[2] * calcDetail.portalhotels)+
                                            (array1[3] * calcDetail.nonportalhotels)+
                                            (array1[4] * calcDetail.supermarkets)+
                                            (array1[5] * calcDetail.othertravel)+
                                            (array1[6] * calcDetail.dining)+
                                            (array1[7] * calcDetail.drugstores)+
                                            (array1[8] * calcDetail.otherspend)
                                        )*(calcDetail.statementcredit / 100)
                                         + (((calcDetail.monthlydining + calcDetail.monthlyentertainment) * 12)+
                                            (calcDetail.annualtravel)+
                                            (calcDetail.quarterlytravel * 4)+
                                            (calcDetail.storecredit)+
                                            (calcDetail.clearcredit)) - (calcDetail.annualfee)) *100 )/100).toFixed(2)
                                        }
                                    </Card.Body>
                                    <Card.Text>Possible returns in the form of travel portal credit:</Card.Text>
                                    <Card.Body key={index}>
                                   $ {
                                    (Math.round (((
                                            (array1[0] * calcDetail.portalflights)+
                                            (array1[1] * calcDetail.nonportalflights)+
                                            (array1[2] * calcDetail.portalhotels)+
                                            (array1[3] * calcDetail.nonportalhotels)+
                                            (array1[4] * calcDetail.supermarkets)+
                                            (array1[5] * calcDetail.othertravel)+
                                            (array1[6] * calcDetail.dining)+
                                            (array1[7] * calcDetail.drugstores)+
                                            (array1[8] * calcDetail.otherspend)
                                        )*(calcDetail.nativetravelportal / 100)
                                         + (((calcDetail.monthlydining + calcDetail.monthlyentertainment) * 12)+
                                            (calcDetail.annualtravel)+
                                            (calcDetail.quarterlytravel * 4)+
                                            (calcDetail.storecredit)+
                                            (calcDetail.clearcredit)) - (calcDetail.annualfee)) *100 )/100).toFixed(2)
                                        }
                                    </Card.Body>
                                    <Card.Text>Possible value if redeemed for gift cards:</Card.Text>
                                    <Card.Body key={index}>
                                    $ {
                                        (Math.round (((
                                            (array1[0] * calcDetail.portalflights)+
                                            (array1[1] * calcDetail.nonportalflights)+
                                            (array1[2] * calcDetail.portalhotels)+
                                            (array1[3] * calcDetail.nonportalhotels)+
                                            (array1[4] * calcDetail.supermarkets)+
                                            (array1[5] * calcDetail.othertravel)+
                                            (array1[6] * calcDetail.dining)+
                                            (array1[7] * calcDetail.drugstores)+
                                            (array1[8] * calcDetail.otherspend)
                                        )*(calcDetail.portalgiftcards / 100)
                                         + (((calcDetail.monthlydining + calcDetail.monthlyentertainment) * 12)+
                                            (calcDetail.annualtravel)+
                                            (calcDetail.quarterlytravel * 4)+
                                            (calcDetail.storecredit)+
                                            (calcDetail.clearcredit)) - (calcDetail.annualfee)) *100 )/100).toFixed(2)
                                        }
                                    </Card.Body>
                                    <Card.Text>
                                    </Card.Text>
                                    </Card>})}
                    </Row>
                </div>
            </div>
        </Container>
    );
}


/////////////////god, buddha, anyone, please

