import '../App.css'
export default function About() {

    return (
        
        <>
            <div>
            
            
                <br/>
                <br/>
                <br/>

                <div className='mainHolder'>
                    <div className='eyeCatch'> What is Credit Newbie?</div>
                    <br/>
                    <br/>
                    <br/>
                    <div className='meatParagraph'>
                Credit Newbie seeks to clear up confusion and misinformation in the world of credit for those
                who are new to owning credit or looking to jump in to the world of credit.
                </div>
                </div>
                
            </div>

        </>

    );
}