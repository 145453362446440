import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button, Form, CardImg, Image, CardGroup, CardText} from 'react-bootstrap';
import { useState, useEffect } from 'react';



export default function CardBlock (props) {

    const cardsPull = props.cardsPull; //crucial to the functionality of pulling from supabase
    return (
        <>
           <Card style={{width: "25rem"}}>
                <Card.Body>
                    <Card.Img src={cardsPull.cardim}/>
                    <Card.Title>{cardsPull.cardname}</Card.Title>
                    <Card.Text>Reward type: {cardsPull.cashbackorpoints}</Card.Text>
                    <Card.Text>Annual Fee: ${cardsPull.annualfee}</Card.Text>
                    <Card.Text>Recommended Minimum Credit Score: {cardsPull.minscoreneeded}</Card.Text>
                    <Card.Text>{cardsPull.cardcreditscid}</Card.Text>
                </Card.Body>
           </Card>
           <br/>
        </>
    );
}