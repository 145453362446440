import React from 'react';
import '../index.css';
import '../App.css';
import { useState, useEffect } from 'react';
import { supabase } from '../client';

export default function Login() {

    const [logor,setLogor] = useState('Login');
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState({ userid: "", fname: "", lname: "", pwordhash: "", pwordsalt: ""});
    const {userid, fname, lname, pwordhash, pwordsalt} = post;

    useEffect(() => {
        fetchPosts()
    }, [])

    async function fetchPosts(){
        const { users } = await supabase
        .from('users')
        .select()
        setPosts(users)
        console.log("users: ", users)
    }
    async function createUser(){
        await supabase
        .from('users')
        .insert([
        {userid, fname, lname, pwordhash, pwordsalt}
        ])
        .single()
        setPost({ userid: "", fname: "", lname: "", pwordhash: "", pwordsalt: ""})
        fetchPosts()
    }


  return(
    <div className ="App">
        <div>
            {/* <div>
            <input
                placeholder='user id'
                value={userid}
                onChange={e =>setPost({ ...post, userid: e.target.value })}
            />
            <input
                placeholder='fname'
                value={fname}
                onChange={e =>setPost({ ...post, fname: e.target.value })}
            />
            <input
                placeholder='lname'
                value={lname}
                onChange={e =>setPost({ ...post, lname: e.target.value })}
            />
            <input
                placeholder='pwordhash'
                value={pwordhash}
                onChange={e =>setPost({ ...post, pwordhash: e.target.value })}
            />
            <input
                placeholder='pwordsalt'
                value={pwordsalt}
                onChange={e =>setPost({ ...post, pwordsalt: e.target.value })}
            />
            <button onClick={createUser}>Create User</button>
            
            </div>*/}
        </div>  
        <div className='SignupPage'>
            <div className='SignupHeader'>{logor}</div>
            <br />
            
            <br />
            <br />
            {logor==='Login'?
            <>
            <div className='eyeCatch'>Welcome back to Credit Newbie!</div>
            <br/>
            <br/>
            <br/>
            </>
            :
            <div></div>
            }
            
            {logor==='Signup'?
            <div>
                <div className='eyeCatch'>Welcome to Credit Newbie!</div>

                <br/>
                <br/>
                <br/>
                <div className='SignupHeader' >Please provide the appropriate information below to get started.</div>
                <br/>
                <br/>
                <div className='fnlnSpacing'>
                <br />
                <br />
                <br />
                <br />
                <div className='onlyForSignup'>
                    <div className='LoginSignupPrompts'>First Name
                        <div className='inputs'>
                            <img src ='' alt=''/>
                            <input
                                placeholder=''
                                value={fname}
                                onChange={e =>setPost({ ...post, fname: e.target.value })}
                            />
                        </div>
                    </div>
                    <br/>
                    
                    <div className='LoginSignupPrompts'>Last Name
                        <div className='inputs'>
                            <img src ='' alt=''/>
                            <input
                                placeholder=''
                                value={lname}
                                onChange={e =>setPost({ ...post, lname: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            :
            <></>
            }
            
            
            <br />
            <div className='LoginSignupPrompts'>Email Address</div>
            <div className='inputs'>
                <img src ='' alt=''/>
                <input type='email' />
            </div>
            <br />
            <div className='LoginSignupPrompts'>Password</div>
            <div className='inputs'>
                <img src ='' alt=''/>
                <input type='password'/>
            </div>
            <br />
            <div className='forgotPassword'>Forgot Password? <span>Click Here</span></div>


            <div className='loginOrSignupContainer'>
                <div className='loginOrSignup'>
                    <div className={logor==='Login'?'submitGrey' : 'submit'} onClick={()=>{setLogor('Signup')}}>Signup</div>
                </div>
                <div className='loginOrSignup'>
                    <div className={logor==='Signup'?'submitGrey' : 'submit'} onClick={()=>{setLogor('Login')}}>Login</div>
                </div>
            </div>

            <div className='loginOrSignupContainer'>
                <button className='loginOrSignup'>
                    <div>Continue</div>
                </button>
                    
            </div>
        </div>
    </div>
    );
}