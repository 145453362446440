import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AIRLINESRC from '../Airlines.json';
import HOTELSRC from '../Hotels.json'
import { Col, Row, Card, Container, Header} from 'react-bootstrap';


export default function TravelTables () {

    return (
        
                    
            <Container>
            <br/>
            <br/>
                        <h1 className='SignUpHeader'>Here is a sampling of some of the airline and hotel partners that the cards presented here may partner with</h1>
                        <Row className=''>
                            {AIRLINESRC.map((al) => {
                                return (
                                    <Card style={{width:"25rem"}} className='cardBlock'>
                                    <Card.Img src={al.airlineim}/>
                                    <Card.Header>{al.airlineName}</Card.Header>
                                    <Card.Body>Average Cents Per Point Value of Loyalty Points: {al.airlinePointRedemptionValue}</Card.Body>
                                    <Card.Text>Learn More at: {al.airlinewebsite}</Card.Text>
                                </Card>
                                )
                            })}
                        </Row>
                        <br/>

                        <br/>
                        <Row>
                            {HOTELSRC.map((hot) => {
                                return (<Card style={{width:"28rem"}} className='cardBlock'>
                                    <Card.Img src={hot.hotelImg}/>
                                    <Card.Header>{hot.hotelName}</Card.Header>
                                    <Card.Body>Average Cents Per Point Value of Loyalty Points: {hot.hotelPointRedemptionValue}</Card.Body>
                                    <Card.Text>Learn More at: {hot.hotelWebsite}</Card.Text>
                                </Card>)
                            })}
                        </Row>
        </Container>

    )
}
    