import React from "react";
import '../App.css';

export default function DisclaimerFooter(){


    return(
        <div className="darkBG">
            <div>
                Credit Newbie and its contributors are not financial advisors, nor should this be taken as explicit
                financial advice. The information provided is accumulated from personal life experience in addition to 
                research done into average values of various redemption methods direct from card issuers or travel partners.
                If you or a loved one are experiencing financial hardship or mental health concerns due to similar concerns, 
                please seek out proper help.
            </div>
        </div>
    )
}