import './App.css';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import About from './Pages/About';
import Calc from "./Pages/Calc";
import CardsDefaultAll from "./Pages/CardsDefaultAll";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import UserDash from "./Pages/UserDash";
import NoPage from './Pages/NoPage';
import Banner from './Components/Banner';
import TravelPartners from './Pages/TravelPartners';
import TravelTables from './Components/TravelTables';
import DisclaimerFooter from './Components/DisclaimerFooter';
import Secret from './Pages/Secret';


const App = () => {
  return(
    <div className ="App">
      <Banner />
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/credit-cards' element={<CardsDefaultAll />} />
          <Route path='/travel-partners' element={<TravelTables/>}/>
          <Route path='/main-calc' element={<Calc />} />
          <Route path='/login' element={<Login />} />
          <Route path='/user-dashboard' element={<UserDash />} />
          <Route path='*' element={<NoPage />} />
          <Route path='secret' element={<Secret/>}/>

        </Routes>
      </BrowserRouter>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <DisclaimerFooter/>
    </div>
  )
  
  
}

export default App;
