import React from "react";

export default function Secret(){
    return(
        <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <img src="https://cdn.discordapp.com/attachments/1007754729306202124/1185266009075089470/89hrvo.png?ex=658efbfd&is=657c86fd&hm=b0a70ffe75177eaca799dbef2da288fc964627e3fc444fed56b277186b325f8c&" />
            <img src="https://cdn.discordapp.com/attachments/1007754729306202124/1185266009414844476/89hrzm.png?ex=658efbfd&is=657c86fd&hm=292fad101a7f8563312b7be197987f9f727034adea97d7dfaa7d98a269e7b734&" />
        </div>
    )
}