import React from 'react';
import homebutton from '../App.css';
import cnLogo from '../Assets/LogoMain.png';
import navbartext from '../App.css'
import navbarlayout from '../App.css'


const Banner = () => { 
    return (


        <div className='navbarlayout'>
            <img  srcSet={cnLogo} id='homebutton'/>
            
            <a href="/" id='navbartext'>
                Home
            </a>
            <a href='/about' id='navbartext'>
                About
            </a>
            <a href='/credit-cards' id='navbartext'>
                Credit Cards
            </a>
            <a href='/main-calc' id='navbartext'>
                Our Credit Calculator
            </a>
            <a href='/travel-partners' id='navbartext'>
                Travel Partners
            </a>
            <a href='/login' id='navbartext'>
                Login
            </a>
        </div>
    );
        
}

export default Banner;

